import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepButton from '@mui/material/StepButton';
import StepLabel from '@mui/material/StepLabel';

export default function AgentPlanStepper(
    { agentPlan, activeStep, setActiveStep, runningStep, completed, failed }) {
    const handleClick = (step) => () => {
        setActiveStep(step);
    };
    
    return (       
        <Stepper nonLinear activeStep={activeStep}
                    sx={{width: "100%", padding: "8px"}}>
            {agentPlan && agentPlan.map((step, index) => (
                <Step completed={completed && completed[index]}
                        key={ index }>
                    <StepButton
                        color="inherit" onClick={handleClick(index)}>
                            <StepLabel error={failed && failed[index]}>
                                { step.description }
                                { runningStep === index && <CircularProgress size={16} /> }
                            </StepLabel>
                    </StepButton>
                </Step>
            ))}
        </Stepper>
    );
}