import { Box } from '@mui/material'
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';

export default function StepParameters({ agentPlan, setAgentPlan, activeStep,
                          modifiedParameters, setModifiedParameters }) {
    let stepParameters = [];
    if (agentPlan && agentPlan[activeStep]) {
        stepParameters = agentPlan[activeStep].parameters
    }
    return (
        <Box height="33%" sx={{padding: "8px"}}>
            { stepParameters.map((param, index) => (
                <Tooltip title={param.description} key={index}>
                    <TextField label={param.name} value={param.value} fullWidth
                        onChange={(event) => {
                            const newAgentPlan = Object.assign([], agentPlan);
                            if (Array.isArray(agentPlan[activeStep].parameters[index].value)) {
                                // If the value is a list, split the input string by
                                // commas and trim the whitespace from each element.
                                newAgentPlan[activeStep].parameters[index].value =
                                    event.target.value.split(',').map((x) => x.trim());
                            } else {
                                newAgentPlan[activeStep].parameters[index].value = event.target.value;
                            }
                            setAgentPlan(newAgentPlan);
                            const newModifiedParameters = Object.assign({}, modifiedParameters);
                            newModifiedParameters[param.name] = newAgentPlan[activeStep].parameters[index].value;
                            setModifiedParameters(newModifiedParameters);
                        }}
                    />
                </Tooltip>
            ))}
        </Box>
    );
}