import { useAuth0 } from '@auth0/auth0-react';
import React from 'react';

export default function useAgentStream(
    parseFileTree, parseFileDiff, parseMarkdownCodegen, agentId) {

    const { getAccessTokenSilently } = useAuth0();
    const [agentStreams, setAgentStreams] = React.useState({});
    const [terminalOutputs, setTerminalOutputs] = React.useState({});
    // The step that is currently running.
    const [runningStep, setRunningStep] = React.useState(-1);
    const [completed, setCompleted] = React.useState({});
    const [failed, setFailed] = React.useState({});
    // The step with current focus in the stepper.
    const [activeStep, setActiveStep] = React.useState(0);

    // Fetch the agent stream adding messages or updated files.
    React.useEffect(() => {
        const fetchData = async (attempt) => {
            const token = await getAccessTokenSilently();
            const response = await fetch('/api/stream_agent', {
                method: 'POST',
                body: JSON.stringify({ agentId: agentId }),
                headers: {
                    'Authorization': `Bearer ${token}`,
                }
            });
            if (response.status !== 200 || !response.body) {
                return;
            }
            // Keep a local copy of the state to avoid recursively triggering
            // the effect when the state is updated below.
            let currentRunning = -1;
            let completedSteps = {};
            let failedSteps = {};
            // stateTransition updates the workflow state based on the agent state
            // message.
            const stateTransition = (agentState) => {
                switch (agentState.state) {
                    case "RUNNING":
                        setActiveStep(agentState.stepId);
                        currentRunning = agentState.stepId;
                        setRunningStep(currentRunning);
                        if (completedSteps[agentState.stepId]) {
                            // Returning to this completed step.
                            completedSteps[agentState.stepId] = false;
                            setCompleted(completedSteps);
                        }
                        if (failedSteps[agentState.stepId]) {
                            // Returning to this failed step.
                            failedSteps[agentState.stepId] = false;
                            setFailed(failedSteps);
                        }
                        break;
                    case "COMPLETED":
                        completedSteps[agentState.stepId] = true;
                        setCompleted(completedSteps);
                        currentRunning = -1;
                        setRunningStep(-1);
                        break;
                    case "FAILED":
                        failedSteps[agentState.stepId] = true;
                        setFailed(failedSteps);
                        currentRunning = -1;
                        setRunningStep(-1);
                        break;
                    default:
                        break;
                }
            }
            // Read the stream of messages from the agent.
            const reader = response.body.pipeThrough(
                new TextDecoderStream()).getReader();
            let agentMessages = {};
            let terminalMessages = {};
            while (true) {
                try {
                    const { value, done } = await reader.read();
                    if (done) {
                        break;
                    }
                    const lines = value.split('\x1e'); // ASCII record separator.
                    for (let i = 0; i < lines.length - 1; i++) {
                        const jsonValue = JSON.parse(lines[i]);
                        if (jsonValue.message) {
                            agentMessages[currentRunning] = (
                                (agentMessages[currentRunning] || "") +
                                jsonValue.message + '\r');
                            const newAgentMessages = { ...agentMessages };
                            setAgentStreams(newAgentMessages);
                        } else if (jsonValue.stepId != null) {
                            stateTransition(jsonValue);
                        } else if (jsonValue.terminal) {
                            terminalMessages[currentRunning] = (
                                (terminalMessages[currentRunning] || "") +
                                jsonValue.terminal);
                            const newTerminalMessages = { ...terminalMessages };
                            setTerminalOutputs(newTerminalMessages);
                        } else if (jsonValue.tree) {
                            parseFileTree(jsonValue.tree);
                        } else if (jsonValue.diff) {
                            parseFileDiff(jsonValue.diff);
                        } else if (jsonValue.markdown_codegen) {
                            parseMarkdownCodegen(jsonValue.markdown_codegen);
                        }
                    }
                    attempt = 0; // Reset attempt counter if we received data.
                } catch (e) {
                    console.log(e);
                    if (attempt < 3) {
                        fetchData(attempt + 1);
                    }
                    break;                    
                }
            }
        }
        if (agentId !== "") {
            fetchData(0 /*attempt*/);
        }
    }, [agentId, getAccessTokenSilently, parseFileDiff, parseFileTree, parseMarkdownCodegen]);

    return { agentStreams, runningStep, completed, failed,
             activeStep, setActiveStep, terminalOutputs };
}