export const projectAdapterCreater = (projectTemplate, getAccessTokenSilently) => {
  const flaskStreamingAdapter = {
    messageHistory: [],
    streamText: async function(message, observer, extras) {
      this.messageHistory.push({role: "user", content: message});
      const token = await getAccessTokenSilently();
      const response = await fetch('/api/stream_gpt4', {
        method: 'POST',
        body: JSON.stringify({messageHistory: this.messageHistory, projectTemplate: projectTemplate}),
        headers: {
          'Authorization': `Bearer ${token}`,
        }
      });
      if (response.status !== 200) {
        observer.error(new Error('Failed to connect to the server'));
        return;
      }
      if (!response.body) {
        return;
      }
      const reader = response.body.pipeThrough(new TextDecoderStream()).getReader();
      let assistantMessage = '';
      while (true) {
        const { value, done } = await reader.read();
        if (done) {
          break;
        }
        observer.next(value);
        assistantMessage += value;
      }
      observer.complete();
      this.messageHistory.push({role: "assistant", content: assistantMessage});
    }
  }
  return flaskStreamingAdapter;
};

