import React, { useEffect } from 'react';
import { Typography, Grid, TextField, Autocomplete, Stack } from '@mui/material';
import VerticalLinearStepper from './VerticalLinearStepper';
import AgentView from '../AgentView/AgentView';
import ProjectChat from './ProjectChat';
import ProjectTemplate from './ProjectTemplate';
import { useAuth0 } from '@auth0/auth0-react';

export default function ProjectView(
   { setToolbarText, setToolbarButton}) {
  const { getAccessTokenSilently } = useAuth0();
  const [activeTemplateId, setActiveTemplateId] = React.useState(0);
  const [agentId, setAgentId] = React.useState("");
  const [projectTemplates, setProjectTemplates] = React.useState([]);
  const [activeRepository, setActiveRepository] = React.useState("");
  const [agentStarted, setAgentStarted] = React.useState(false);
  const [agentPlan, setAgentPlan] = React.useState([]);

  React.useEffect(() => {
    getAccessTokenSilently().then(token => {
      fetch('/api/fetch_projects', {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        }})
      .then(response => response.json())
      .then(data => { setProjectTemplates(data); });
    });
  }, [getAccessTokenSilently]);
  
  return agentId === "" ? (
    <TopLevelView
      setAgentId={setAgentId}
      activeTemplateId={activeTemplateId}
      setActiveTemplateId={setActiveTemplateId}
      projectTemplates={projectTemplates}
      activeRepository={activeRepository}
      setActiveRepository={setActiveRepository}
      agentStarted={agentStarted}
      setAgentStarted={setAgentStarted}
      setAgentPlan={setAgentPlan}
      setToolbarText={setToolbarText}
      setToolbarButton={setToolbarButton}
    />
  ) : (
    <AgentView
      agentId={agentId}
      setAgentId={setAgentId}
      agentPlan={agentPlan}
      setAgentPlan={setAgentPlan}
      setToolbarText={setToolbarText}
      setToolbarButton={setToolbarButton}
    />
  );
}

function TopLevelView({setAgentId, activeTemplateId, setActiveTemplateId,
                       projectTemplates, activeRepository, setActiveRepository,
                       agentStarted, setAgentStarted, setAgentPlan,
                       setToolbarText, setToolbarButton}) {
  useEffect(() => {
    setToolbarText("Doyen AI");
    setToolbarButton(null);
  });

  const handleTemplateSelect = (event, value) => {
    // Find the project template that matches value.label.
    for (let i = 0; i < projectTemplates.length; i++) {
      if (projectTemplates[i].label === value.label) {
        // Set the active template to that index.
        setActiveTemplateId(i);
        // If the template has an active repository, display (set) it.
        if (projectTemplates[i].activeRepository) {
          setActiveRepository(projectTemplates[i].activeRepository);
        } else {
          setActiveRepository("");
        }
        if (projectTemplates[i].agentStarted) {
          setAgentStarted(true);
        } else {
          setAgentStarted(false);
        }
        // If the template has an active agent plan, display (set) it.
        if (projectTemplates[i].plan) {
          setAgentPlan(projectTemplates[i].plan);
        } else {
          setAgentPlan([]);
        }
        break;
      }
    }
  }
  let selectedTemplate = projectTemplates[activeTemplateId];
  let planSteps = selectedTemplate ? selectedTemplate.planSteps : [];
  return selectedTemplate && (
    <Grid container height="100%">
      <Grid item xs={6}>
        <Stack height="100%">          
            <Autocomplete
              disablePortal
              id="combo-box"
              value={selectedTemplate}
              options={projectTemplates}
              onChange={handleTemplateSelect}
              sx={{ width: "100%", padding: '8px' }}
              renderInput={(params) => <TextField {...params} label="Select a Project Plan template" />}
            />
            <Typography variant="h4">Project Plan</Typography>
            <div style={{ display: 'flex', justifyContent: 'center', padding: '60px', height: '90%'}}>
              <VerticalLinearStepper steps={planSteps} setAgentId={setAgentId} />
            </div>
        </Stack>
      </Grid>
      <Grid item xs={6}>
        <Stack height="100%">
          <ProjectTemplate
             projectTemplates={projectTemplates}
             activeTemplateId={activeTemplateId}
             activeRepository={activeRepository}
             setActiveRepository={setActiveRepository}
             agentStarted={agentStarted}
             setAgentStarted={setAgentStarted}
             setAgentPlan={setAgentPlan}/>
          <ProjectChat activeTemplate={projectTemplates[activeTemplateId]} />
        </Stack>
      </Grid>
    </Grid>
  )
}
