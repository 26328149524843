import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';

export default function useFiles(agentId) {
    const [fileTreeData, setFileTreeData] = React.useState({});
    const parseFileTree = React.useCallback((jsonValue) => {
        setFileTreeData(jsonValue);
    }, []);

    let fileStates = React.useRef({});

    // DiffEditor state.
    const [diffEditorPath, setDiffEditorPath] = React.useState("HIDDEN");
    const [activeDiff, setActiveDiff] = React.useState(
        { original: "", new: "", unsaved: "", language: "" });
    const [diffFileList, setDiffFileList] = React.useState([]);
    // Parser for the diff json object received from the agent, adds entries
    // to the diffFileList and sets the active diff.
    const parseFileDiff = React.useCallback((jsonValue) => {
        let i = 0;
        let tmpDiffFileList = Object.keys(jsonValue);
        for (const key in jsonValue) {
            if (i === 0) {
                setDiffEditorPath(key);
                setActiveDiff(jsonValue[key]);
            }
            fileStates.current[key] = jsonValue[key];
            i++;
        }
        setDiffFileList(prevList => {
            const newFiles = tmpDiffFileList.filter(file => !prevList.includes(file));
            return [...prevList, ...newFiles];
        });
    }, []);
    // Set the active diff to the file state.
    React.useEffect(() => {
        if (fileStates.current && diffEditorPath in fileStates.current) {
            setActiveDiff(fileStates.current[diffEditorPath]);
        }
    }, [diffEditorPath]);

    // State for file selected in the file tree, shown in regular Editor.
    const [editorPath, setEditorPath] = React.useState("");
    const [editorLanguage, setEditorLanguage] = React.useState("plaintext");
    const [editorContent, setEditorContent] = React.useState("");
    // Get editor content from fileStates or fetch the file content from the
    // agent if it is not in fileStates.
    const { getAccessTokenSilently } = useAuth0();
    React.useEffect(() => {
        if (fileStates.current[editorPath]) {
            let fileState = fileStates.current[editorPath];
            console.log(fileState);
            setEditorLanguage(fileState.language);
            if (fileState.unsaved) {
                setEditorContent(fileState.unsaved);
            } else if (fileState.new)   {
                setEditorContent(fileState.new);
            } else {
                setEditorContent(fileState.original);
            }
        } else if (editorPath !== "") {
            getAccessTokenSilently().then(token => {
                fetch('/api/fetch_agent_file', {
                    method: 'POST',
                    body: JSON.stringify({ agentId: agentId, path: editorPath }),
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`,
                    }
                })
                .then(response => response.json())
                .then(data => {
                    fileStates.current[editorPath] = data;
                    setEditorLanguage(data.language);
                    setEditorContent(data.original);
                });
            });
        }
    }, [editorPath, agentId, getAccessTokenSilently]);

    const changeFile = React.useCallback((path, content) => {
        // Add the file to diff file list if this is the first change.
        if ((!fileStates.current[path].unsaved && !fileStates.current[path].new)) {
            setDiffFileList(prevList => [...prevList, path]);
        }
        fileStates.current[path].unsaved = content;
    }, []);

    const saveFile = React.useCallback((path) => {
        fileStates.current[path].new = fileStates.current[path].unsaved;
        fileStates.current[path].unsaved = "";
        // TODO: Send file to agent for saving.
    }, []);

    return {
        // File tree.
        parseFileTree, fileTreeData,
        // Diff editor.
        parseFileDiff, diffEditorPath, setDiffEditorPath, activeDiff, diffFileList, 
        // Editor.
        editorPath, setEditorPath, editorContent, editorLanguage,
        // File change handlers.
        changeFile, saveFile
    };
}