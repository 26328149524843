export const repoParams = {
    "nodejs": {
        container_image: "node:latest",
        container_env: "NODE_OPTIONS=--openssl-legacy-provider",
        langserv_install_command: "npm install -g typescript-language-server typescript",
        langserv_start_command: "typescript-language-server --stdio",
        init_command: "yarn",
        remove_library_command: "yarn remove",
        add_library_command: "yarn add",
        build_project_command: "yarn build",
        extensions: ['.js', '.ts', '.jsx', '.tsx'],
        ignore_directories: ['node_modules', 'dist', 'build'],
    },
};