import React from 'react';
import Button from '@mui/material/Button';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Grid, Stack, IconButton } from '@mui/material'
import { useAuth0 } from '@auth0/auth0-react';
import AgentPlanStepper from './AgentPlanStepper';
import MarkdownCodegen from './MarkdownCodegen';
import MonacoEditor from './MonacoEditor';
import StepParameters from './StepParameters';
import MessageScroll from './MessageScroll';
import Terminal from './Terminal';
import useAgentStream  from './useAgentStream';
import useFiles from './useFiles';
import useMarkdownCodegen from './useMarkdownCodegen';

function agentIdForDisplay(agentId) {
    return agentId.split('-')[0];
}

export default function AgentView(
    { agentId, setAgentId, agentPlan, setAgentPlan,
      setToolbarText, setToolbarButton }) {
    const {
        // File tree.
        parseFileTree, fileTreeData,
        // Diff editor.
        parseFileDiff, diffEditorPath, setDiffEditorPath, activeDiff, diffFileList, 
        // Editor.
        editorPath, setEditorPath, editorContent, editorLanguage,
        // File change handlers.
        changeFile, saveFile
    } = useFiles(agentId);
    const { parseMarkdownCodegen, markdownPath, setMarkdownPath, markdownFileList,
        activeMarkdownCodegen } = useMarkdownCodegen();
    const { agentStreams, runningStep, completed, failed,
            activeStep, setActiveStep, terminalOutputs } = useAgentStream(
                parseFileTree, parseFileDiff, parseMarkdownCodegen, agentId);
    const { getAccessTokenSilently } = useAuth0();
    const [modifiedParameters, setModifiedParameters] = React.useState({});
    
    React.useEffect(() => {
        const navCallback = () => {
            setAgentId("");
        }
        setToolbarText(`Doyen Agent: ${agentIdForDisplay(agentId)}`);
        setToolbarButton(
            <IconButton onClick={navCallback}><ArrowBackIcon /></IconButton>);
    }, [setToolbarText, setToolbarButton, agentId, setAgentId]);

    const stepIsRestartable = () => {
        // Any step prior to a failed step is restartable.
        for (let i = 0; i < agentPlan.length; i++) {
            if (failed[i] === true && activeStep <= i) {
                return true;
            }
        }
        return false;
    }

    const restart_step = async () => {
        const token = await getAccessTokenSilently();
        // Iterate through keys and values in modifiedParameters and
        // add them to a list of AgentParameter objects.
        let modifiedParametersList = [];
        for (const [key, value] of Object.entries(modifiedParameters)) {
            modifiedParametersList.push({name: key, value: value});
        }
        const response = await fetch('/api/run_agent_step', {
            method: 'POST',
            body: JSON.stringify(
                { agentId: agentId, rerunStepId: activeStep,
                  modifiedParameters: modifiedParametersList }),
            headers: {
                'Authorization': `Bearer ${token}`,
            }
        });
        if (response.status !== 200) {
            console.log("Failed to restart step");
        }
    }
    let activeAgentStep = agentPlan[activeStep];
    return (
        <>
            <AgentPlanStepper
                agentPlan={agentPlan}
                activeStep={activeStep}
                setActiveStep={setActiveStep}
                runningStep={runningStep}
                completed={completed}
                failed={failed}/>
            <Grid container sx={{ flexGrow: 1 }}>
                <Grid item xs={2.5}>
                    <Stack height="100%">
                        { stepIsRestartable() && (
                            <Button onClick={ restart_step } variant={"contained"}
                                    sx={{mb: 1}}>
                                Restart Step
                            </Button>
                        )}
                        <StepParameters
                            agentPlan={ agentPlan }
                            setAgentPlan={ setAgentPlan }
                            modifiedParameters={ modifiedParameters }
                            setModifiedParameters={ setModifiedParameters }
                            activeStep={ activeStep }
                            />
                        <MessageScroll
                            agentStreams={ agentStreams }
                            activeStep={ activeStep } />
                    </Stack>
                </Grid>
                <Grid item xs={9.5}>
                {
                    (() => {
                        switch (activeAgentStep?.displayType) {
                            case "FILEDIFF":
                                return (
                                    <MonacoEditor
                                        fileTreeData={ fileTreeData }
                                        diffEditorPath={ diffEditorPath }
                                        setDiffEditorPath={ setDiffEditorPath }
                                        diffFileList={ diffFileList } 
                                        activeDiff={ activeDiff }
                                        editorPath={ editorPath }
                                        setEditorPath={ setEditorPath }
                                        editorContent={ editorContent }
                                        editorLanguage={ editorLanguage }
                                        changeFile={ changeFile }
                                        saveFile={ saveFile }
                                    />
                                );
                            case "TERMINAL":
                                return <Terminal 
                                        outputs={ terminalOutputs[activeStep] }
                                        activeStep={ activeStep } />;
                            case "MARKDOWNCODEGEN":
                                return <MarkdownCodegen 
                                    markdownPath={ markdownPath }
                                    setMarkdownPath={ setMarkdownPath }
                                    markdownFileList={ markdownFileList }
                                    activeMarkdownCodegen={ activeMarkdownCodegen }
                                />;
                            default:
                                return null;
                        }
                    })()
                }
                </Grid>
            </Grid>
        </>
    )
}
