import React from 'react';
import Editor, { DiffEditor } from '@monaco-editor/react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import FileTree from './FileTree';
import { Button, Toolbar, Stack } from '@mui/material';
import FolderOpenIcon from '@mui/icons-material/FolderOpen';
import FolderIcon from '@mui/icons-material/Folder';

export default function MonacoEditor(
    { 
        // File tree.
        fileTreeData,
        // Diff editor.
        diffEditorPath, setDiffEditorPath, diffFileList, activeDiff,
        // Editor.
        editorPath, setEditorPath, editorContent, editorLanguage,
        // File change handlers.
        changeFile, saveFile
    }) {
    // Disable semantic and syntax validation for now.
    const handleEditorWillMount = (monaco) => {
        monaco.languages.typescript.typescriptDefaults.setDiagnosticsOptions({
            noSemanticValidation: true,
            noSyntaxValidation: true,
          });
        monaco.languages.typescript.javascriptDefaults.setDiagnosticsOptions({
            noSemanticValidation: true,
            noSyntaxValidation: true,
          });
    };

    const [isFileTreeVisible, setFileTreeVisible] = React.useState(false);
    const toggleFileTree = () => {
        setFileTreeVisible(!isFileTreeVisible);
    }
    const [showDiff, setShowDiff] = React.useState(true);
    // Creates handlers for content edits in an editor.
    const makeFileChangeHandler = (editorRef) => {
        let lastPath = "";
        return (e) => {
            let path = editorRef.current.getModel().uri.path;
            if (lastPath === path) {
                let value = editorRef.current.getValue();
                changeFile(path, value);
            } else { // Ignore file change events.
                lastPath = path;
            }
        }
    }
    const diffModifiedEditorRef = React.useRef(null);
    const handleDiffEditorDidMount = (diffEditor, monaco) => {
        diffModifiedEditorRef.current = diffEditor.getModifiedEditor();
        diffModifiedEditorRef.current.onDidChangeModelContent(
            makeFileChangeHandler(diffModifiedEditorRef));
    }
    const editorRef = React.useRef(null);
    const handleEditorDidMount = (editor, monaco) => {
        editorRef.current = editor;
        editorRef.current.onDidChangeModelContent(
            makeFileChangeHandler(editorRef));
    }
    let modified = activeDiff.unsaved ? activeDiff.unsaved : activeDiff.new;
    return (
        <Stack height="100%">
            <Toolbar>
                <Button onClick={toggleFileTree}>
                    {isFileTreeVisible ? <FolderIcon /> : <FolderOpenIcon /> }
                </Button>            
                <Tabs
                    key="tabs"
                    value={ showDiff ? diffEditorPath : "HIDDEN" }
                    onChange={ (event, newValue) => {
                        setDiffEditorPath(newValue);
                        setShowDiff(true);
                    } }
                    variant="scrollable"
                    scrollButtons="auto"
                    aria-label="scrollable auto tabs example">
                    { diffFileList.map((file) => (
                        <Tab label={ file } value={ file } key={ file }
                            sx={{textTransform: 'none'}}
                            onClick={ () => {
                                setShowDiff(true);
                            } } />
                    ))}
                    <Tab label="HIDDEN" value="HIDDEN" style={{ display: 'none' }} />
                </Tabs>
            </Toolbar>
            <Stack height="100%" direction="row">
                <div style={{ display: isFileTreeVisible ? 'block' : 'none' }}>
                    <FileTree
                        data={ fileTreeData }
                        setFileTreePath={ setEditorPath }
                        showDiff={ showDiff }
                        setShowDiff={ setShowDiff }/>
                </div>
                { showDiff ? (
                        <DiffEditor
                            key={ diffEditorPath }
                            height="100%"
                            language={ activeDiff.language }
                            theme="vs-dark"
                            original={ activeDiff.original }
                            modified={ modified }
                            modifiedModelPath={ diffEditorPath }
                            beforeMount={ handleEditorWillMount }
                            onMount={ handleDiffEditorDidMount }
                        />) : (
                        <Editor
                            height="100%"
                            theme="vs-dark"
                            path={ editorPath }
                            language={ editorLanguage }
                            value={ editorContent }
                            onMount={ handleEditorDidMount }
                        />)
                }
            </Stack>
        </Stack>    
    );
}