import {AiChat} from '@nlux/react';
import {projectAdapterCreater} from './chatAdapter'
import { useAuth0 } from '@auth0/auth0-react';
import '@nlux/themes/nova.css';
import './custom-nova-theme.css';
import './chat.css';
import { Box } from '@mui/material';

export default function ProjectChat({ activeTemplate }) {
    const { getAccessTokenSilently } = useAuth0();
    let promptAdapter = projectAdapterCreater(activeTemplate, getAccessTokenSilently);
  
    let templateLabel = activeTemplate ? activeTemplate.label : "";
    return (
      <Box height="50%">
        <div className="chat-header"></div>
        <div style={{ height: "95%", border: "1px solid #d8d2d2" }}>
          <AiChat
            key={templateLabel}
            className="custom-ai-chat-comp"
            adapter={promptAdapter}
            layoutOptions={{
              height: "100%",
              maxHeight: "425px",
              maxWidth: "100%",
            }}
            promptBoxOptions={{
              autoFocus: true,
              placeholder: "Chat with your Doyen Agent about this Project"
            }}
          />
        </div>
      </Box>);
  }