import React from 'react';
import Markdown from 'react-markdown';
import Typography from '@mui/material/Typography';

export default function MessageScroll({ agentStreams, activeStep }) {
    const scrollRef = React.useRef(null);

    // Causes the message list (div below) to scroll to the bottom when new messages are added.
    React.useEffect(() => {
        if (scrollRef.current !== null) {
            scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
        }
    });
    let agentStream = "";
    if (agentStreams && agentStreams[activeStep])
        agentStream = agentStreams[activeStep];
    
    return <>
        <Typography variant="h6">Agent Messages</Typography> 
        <div style={{ height: '66%', overflowY: 'scroll' }} ref={scrollRef}>
            {agentStream.split('\r').map((line, index) => {
                line = line.replace(/\\n/g, '\n');
                return <Markdown key={index} children={line} />
            })}
        </div>
    </>
}