import React from 'react';

export default function useMarkdownCodegen() {
    const [markdownPath, setMarkdownPath] = React.useState("");
    const [markdownFileList, setMarkdownFileList] = React.useState([]);
    const [activeMarkdownCodegen, setActiveMarkdownCodegen] = React.useState(
        { markdown: "", code: "", language: "" });
    let markdownCodegens = React.useRef({});

    const parseMarkdownCodegen = React.useCallback((jsonValue) => {
        let i = 0;
        let tmpMarkdownFileList = Object.keys(jsonValue);
        for (const key in jsonValue) {
            if (i === 0) {
                setMarkdownPath(key);
                setActiveMarkdownCodegen(jsonValue[key]);
            }
            markdownCodegens.current[key] = jsonValue[key];
            i++;
        }
        setMarkdownFileList(prevList => {
            const newFiles = tmpMarkdownFileList.filter(file => !prevList.includes(file));
            return [...prevList, ...newFiles];
        });
    }, []);
    // Set the active code gen display whenever path changes.
    React.useEffect(() => {
        if (markdownCodegens.current && markdownPath in markdownCodegens.current) {
            setActiveMarkdownCodegen(markdownCodegens.current[markdownPath]);
        }
    }, [markdownPath]);

    return { parseMarkdownCodegen, markdownPath, setMarkdownPath, markdownFileList,
        activeMarkdownCodegen };
}

