import React from 'react';
import Editor from '@monaco-editor/react';
import { Stack, Tab, Tabs, Toolbar } from '@mui/material';
import Markdown from 'react-markdown';
import remarkGfm from 'remark-gfm';

export default function MarkdownCodegen({
    markdownPath, setMarkdownPath, markdownFileList,
    activeMarkdownCodegen
}) {
    return (
        <Stack height="100%">
            <Toolbar>
                <Tabs
                    key="tabs"
                    value={ markdownPath }
                    onChange={ (event, newValue) => {
                        setMarkdownPath(newValue);
                    } }
                    variant="scrollable"
                    scrollButtons="auto"
                    aria-label="scrollable auto tabs example">
                    { markdownFileList.map((file) => (
                        <Tab label={ file } value={ file } key={ file }
                            sx={{textTransform: 'none'}} />
                    ))}
                </Tabs>
            </Toolbar>
            <Stack height="100%" direction="row">
                <div style={{ width: '50%', height: "700px", overflowY: 'scroll' }}>
                    <Markdown remarkPlugins={[remarkGfm]} >{ activeMarkdownCodegen.markdown }</Markdown>
                </div>
                <Editor
                    height="100%"
                    width="50%"
                    theme="vs-dark"
                    language={ activeMarkdownCodegen.language }
                    value={ activeMarkdownCodegen.code }
                />
            </Stack>
        </Stack>);
}