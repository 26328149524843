import { FitAddon } from "@xterm/addon-fit";
import { Terminal } from "@xterm/xterm";
import React from "react";

export const useTerminal = (outputs, activeStep) => {
    const terminal = React.useRef(null);
    const fitAddon = React.useRef(null);
    const ref = React.useRef(null);
    const lastOutputIndex = React.useRef(0);

    React.useEffect(() => {
        /* Create a new terminal instance */
        terminal.current = new Terminal({
            fontFamily: "Menlo, Monaco, 'Courier New', monospace",
            fontSize: 14,
            theme: {
            background: "#262626",
            },
        });
        fitAddon.current = new FitAddon();
    
        let resizeObserver;
        if (ref.current) {
            /* Initialize the terminal in the DOM */
            terminal.current.loadAddon(fitAddon.current);
            terminal.current.open(ref.current);

            /* Listen for resize events */
            resizeObserver = new ResizeObserver(() => {
                fitAddon.current?.fit();
            });
            resizeObserver.observe(ref.current);
        }

        return () => {
            terminal.current?.dispose();
            resizeObserver.disconnect();
          };
    }, []);

    React.useEffect(() => {
        lastOutputIndex.current = 0;
        terminal.current?.clear();
    }, [activeStep]);

    React.useEffect(() => {
        /* Write outputs to the terminal */
        if (terminal.current && outputs?.length > 0) {
            // Start writing output from the last index location.
            for (let i = lastOutputIndex.current; i < outputs.length; i += 1) {
                let latestOutput = outputs.slice(lastOutputIndex.current);
                terminal.current?.write(latestOutput);
                lastOutputIndex.current = outputs.length;
            }
        }
    }, [outputs]);

    return ref;
}