import React, { useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { AppBar, Toolbar, Typography, Box } from '@mui/material';
import ProjectView from './ProjectView/ProjectView'
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from '@mui/material';

function App() {
  const { isAuthenticated, loginWithRedirect, logout, user } = useAuth0();

  const customerName = user ? user.name : '';
  const [toolbarText, setToolbarText] = useState('Doyen AI');
  const [toolbarButton, setToolbarButton] = useState(null);
  
  if (isAuthenticated) {
    /*AppBar at the top and an icon that opens a Drawer on the left with menu buttons.*/
    return (
      <Box sx={{ display: 'flex', height: '100vh', flexDirection: 'column' }}>
        <AppBar position="static" enableColorOnDark={true}>
          <Toolbar>
            { toolbarButton }
            <Typography variant="h6" noWrap component="div">
              { toolbarText }
            </Typography>            
            <div style={{ flexGrow: 1 }} />
            <Typography variant="body1">{customerName}</Typography>
            <Button
              onClick={() => logout({ returnTo: window.location.origin })}
              color="inherit">
              Log Out
            </Button>
          </Toolbar>
        </AppBar>

        <ProjectView 
          setToolbarText={setToolbarText}
          setToolbarButton={setToolbarButton}
        />
      </Box>
    );
  } else {
    return (
      <Dialog open={!isAuthenticated} onClose={() => {}}>
        <DialogTitle>Welcome to Doyen AI Demo</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please click the login button to proceed.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={loginWithRedirect} color="primary" variant="contained">
            Login
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default App;
