import { repoParams } from './repoParams';
import { useAuth0 } from '@auth0/auth0-react';
import { Typography, TextField, Box, Button } from '@mui/material';
import React from 'react';

function formatAgentId(selectedTemplate, activeRepository, userEmail) {
    let repo_name = ( activeRepository ?
        activeRepository.split('/').pop().replace('.git', '') : '');
    return (selectedTemplate.agentId + '-' +
        selectedTemplate.label + '-' + repo_name + '-' + userEmail);
}

export default function ProjectTemplate(
    { projectTemplates, activeTemplateId, activeRepository, setActiveRepository,
      agentStarted, setAgentStarted, setAgentPlan }) {
    const { getAccessTokenSilently, user } = useAuth0();

    const handleStartAgent = () => {
        let activeTemplate = projectTemplates[activeTemplateId];
        if (projectTemplates[activeTemplateId].agentId) {
        let agentId = formatAgentId(activeTemplate, activeRepository, user.email);
        for (let i = 0; i < activeTemplate.planSteps.length; i++) {
            if (activeTemplate.planSteps[i].agentId) {
            activeTemplate.planSteps[i].agentId = agentId;
            }
        }
        let params = activeTemplate.agentParams;
        if (!params.repoParams) {
            let repoType = 'nodejs'; // TODO: Make this selectable.
            params.repoParams = repoParams[repoType];
        }

        getAccessTokenSilently().then(token => {
            fetch('/api/start_agent', {
            method: 'POST',
            body: JSON.stringify({ activeRepository, agentId, params }),
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            }
            }).then(response => response.json()).then(data => {
                projectTemplates[activeTemplateId].activeRepository = activeRepository;
                projectTemplates[activeTemplateId].agentStarted = true;
                projectTemplates[activeTemplateId].plan = data;
                setAgentPlan(data);
                setAgentStarted(true);
            })
        });
    }
}

let selectedTemplate = projectTemplates[activeTemplateId];
let templateLabel = selectedTemplate ? selectedTemplate.label : "";
let templateMessage = selectedTemplate ? selectedTemplate.message : "";
let templateHasCode = selectedTemplate ? selectedTemplate.hasCode : false;
return (
    <Box height="50%">
        <Typography variant='h4' padding='16px'>{templateLabel}</Typography>
        <Typography padding='16px'>{templateMessage}</Typography>
        {templateHasCode && (
        <div style={{ display: 'flex', justifyContent: 'center', padding: '8px' }}>
            <TextField
            id="outlined-basic"
            key={templateLabel}
            label="Enter the URL for the Git repo"
            defaultValue={activeRepository}
            variant="outlined"
            sx={{ width: "100%" }}
            onChange={(event)=>{ setActiveRepository(event.target.value) }}/>
            <Button
            disabled={activeRepository === ""}
            onClick={handleStartAgent}> Start Agent </Button>
        </div>
        )}
        {agentStarted && (
        <Typography padding='16px'>Agent started, click Agent View in the corresponding plan step to follow along.</Typography>
        )}
    </Box>
);
}