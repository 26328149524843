import React from "react";
import { DiCss3, DiJavascript, DiNpm } from "react-icons/di";
import { FaList, FaRegFolder, FaRegFolderOpen } from "react-icons/fa";
import TreeView, { flattenTree } from "react-accessible-treeview";
import "./FileTree.css";

export default function FileTree(
    { data, setFileTreePath, showDiff, setShowDiff }) {
    let flatData = flattenTree(data);
    const [selectedIds, setSelectedIds] = React.useState([]);

    const handleNodeSelect = ({element, isBranch}) => {
        if (isBranch) return;
        // Recover the full path of the selected file.
        let current = element;
        let path = current.name;
        while (current.parent !== null) {
            const parent = flatData[current.parent];
            path = `${parent.name}/${path}`;
            current = parent;
        }
        setFileTreePath(path);
        setShowDiff(false);
    };

    React.useEffect(() => {
        if (showDiff) {
            setSelectedIds([]);
        }
    }, [showDiff]);

    return (
        <div className="directory">
            <TreeView
                data={flatData}
                onNodeSelect={handleNodeSelect}
                selectedIds={selectedIds}
                nodeRenderer={({
                    element,
                    isBranch,
                    isExpanded,
                    getNodeProps,
                    level
                }) => (
                    <div {...getNodeProps()}
                        style={{ paddingLeft: 20 * (level - 1),
                                display: 'flex', alignItems: 'center',
                                whiteSpace: 'nowrap' }}>
                        {isBranch ? (
                            <FolderIcon isOpen={isExpanded} />
                            ) : (
                            <FileIcon filename={element.name} />
                            )}
                        {element.name}
                    </div>
                )}
            />
        </div>
    );
}

const FolderIcon = ({ isOpen }) => {
    return isOpen ? (
        <FaRegFolderOpen color="e8a87c" className="icon" />
        ) : (
        <FaRegFolder color="e8a87c" className="icon" />
        );
};

const FileIcon = ({ filename }) => {
    const extension = filename.slice(filename.lastIndexOf(".") + 1);
    switch (extension) {
    case "js":
        return <DiJavascript color="yellow" className="icon" />;
    case "css":
        return <DiCss3 color="turquoise" className="icon" />;
    case "json":
        return <FaList color="yellow" className="icon" />;
    case "npmignore":
        return <DiNpm color="red" className="icon" />;
    default:
        return null;
    }
};